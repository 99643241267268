import { makeStyles } from "@material-ui/core";
import { useMemo } from "react";

import LogoTalkhub from "@/assets/images/logo-talkhub.svg";

const useStyles = makeStyles((theme) => ({
  erroPageContainer: {
    width: "100%",
    height: "100%",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexFlow: "column nowrap",
  },

  logo: {
    marginBottom: "60px",
  },

  header: {
    marginBottom: "20px",
    color: theme.palette.primary.main,
    textAlign: "center",
    fontSize: "1.3em",
  },

  message: {
    marginBottom: "20px",
    color: theme.palette.textSecondary.main,
    textAlign: "center",
    lineHeight: 1.6,
    fontWeight: 500,
  },

  footer: {
    color: theme.palette.textSecondary.main,
    textAlign: "center",
    marginBottom: "10px",
    fontWeight: 500,
  },

  link: {
    color: theme.palette.link.main,
    fontWeight: "normal",
  },
}));

const ErrorPage = ({ children, title, message }) => {
  const classes = useStyles();

  const errorPageTitle = useMemo(() => {
    return title || "An unexpected error has occurred";
  }, [title]);

  return (
    <div className={classes.erroPageContainer}>
      <LogoTalkhub className={classes.logo} />

      <h3 className={classes.header}>{errorPageTitle}</h3>

      {message && <p className={classes.message}>{message}</p>}

      <p className={classes.footer}>
        <a className={classes.link} href="mailto:support@talkhub.io">
          Contact Us
        </a>
      </p>

      {children}
    </div>
  );
};

export default ErrorPage;
